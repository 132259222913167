import React from 'react';

import Layout from '../components/Layout/Layout';

import Header, { HeaderType } from '../components/Elements/Common/Header';
import Paragraph, { ParagraphType, TextType } from '../components/Elements/Common/Paragraph';
import Button, { ButtonType, LinkType } from '../components/Elements/Common/Button';

const NotFoundPage = () => {
  return (
    <Layout>
      <main className="not-found">
        <div className="container">
          <div className="row">
            <Header
              type={ HeaderType.H1 }
              text="Page not found"
            />
          </div>
          <div className="row">
            <Header
              type={ HeaderType.H3 }
              text="Sorry."
            />  
            <span className="emoji" role="img" aria-label="Pensive emoji">😔</span>{" "}
          </div>
          <div className="row">  
            <Paragraph
              type={ ParagraphType.REGULAR }
              textType={ TextType.STRING}
              text="We couldn't find what you were looking for."
            />
            <Button
              type={ ButtonType.TERTIARY }
              linkType={ LinkType.INTERNAL }
              link="/"
              text="Go Home"
            />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage
